export default class ProductPostModel {
    constructor(item) {
        this._setId(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setAmount(item);
        this._setPayment(item);
        this._setPercentage(item);
        this._setInterval(item);
        this._setCommission(item);
        this._setHeading(item);
        this._setImage(item);
    }

    _setId({id}) {
        this.id = id;
    }


    _setTitle({title}) {
        this.title = title;
    }

    _setDescription({description}) {
        this.description = description;
    }


    _setImage({image}) {
        this.file = image;
    }


    _setAmount({amount}) {
        this.amount = amount;
    }

    _setPayment({initialPayment}) {
        this.initialPayment = initialPayment;
    }

    _setPercentage({percentageRate}) {
        this.percentageRate = percentageRate;
    }

    _setInterval({loanInterval}) {
        this.loanInterval = loanInterval;
    }

    _setHeading({heading}) {
        this.heading = heading;
    }

    _setCommission({commission}) {
        this.commission = commission;
    }

}
