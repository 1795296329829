import {mapGetters} from "vuex";
import remove from "../../mixins/remove";

export default {
    name: "partners",
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            items: 'partners/_getItems',
        }),
        headers() {
            return [
                {text: 'Title', value: "title"},
                {
                    text: 'Edit',
                    value: "actions",
                    align: "end",
                },
            ];
        },
    },
    methods: {
        removeItem() {
            this.$store.dispatch("partners/deleteItem", this.selected);
            this.clearDialog();
        },
    },
    created() {
        this.$store.dispatch("partners/getItems");
    },
    mixins: [remove],
};
