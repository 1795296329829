import {mapGetters} from "vuex";
import AboutPostModel from "@/views/about/models/about-post.model";

export default {
    name: "about",
    data() {
        return {
            type: null,
            item: {},
            hasData: true
        }
    },
    computed: {
        ...mapGetters({
                aboutTypes: 'about/_getItems',
            }
        )
    },
    methods: {
        getAbout() {
            this.$store.dispatch('about/getItemById', this.type).then((res) => {
                if (!res) {
                    this.hasData = false;
                    this.item = {};
                } else {
                    this.hasData = true;
                    this.item = {...res};
                }
            })
        },
        updateAbout() {
            let payload = new AboutPostModel({...this.item, type: this.type});
            this.$store.dispatch('about/updateItem', payload)
        },
        setAbout() {
            let payload = new AboutPostModel({...this.item, type: this.type});
            this.$store.dispatch('about/setItem', payload)
        },
        approveChanges() {
            if(this.hasData) {
                this.updateAbout();
            }
            else {
                this.setAbout();
            }
        }
    },
    created() {
        this.$store.dispatch('about/getItems');
    },
};
