export default [
    {
        path: '/',
        name: 'public',
        meta: {
            name: 'Public'
        },
        redirect: {
            name: 'about'
        },
        component: () => import(/* webpackChunkName: "public" */ '../components/layout/public/public.component.vue'),
        children: [
            {
                path: '/partners',
                name: 'partners',
                meta: {
                    name: 'Partnyorlar'
                },
                component: () => import(/* webpackChunkName: "partners" */ '../views/partners/partners.component.vue')
            },
            {
                path: '/partners-add',
                name: 'partners-add',
                meta: {
                    name: 'Partnyorlar'
                },
                component: () => import(/* webpackChunkName: "partners-add" */ '../views/partners-add/partners-add.component.vue')
            },
            {
                path: '/partners-edit/:id',
                name: 'partners-edit',
                meta: {
                    name: 'Partnyorlar',
                    edit: true
                },
                component: () => import(/* webpackChunkName: "partners-add" */ '../views/partners-add/partners-add.component.vue')
            },
            {
                path: '/partner-types',
                name: 'partner-types',
                meta: {
                    name: 'Partnyor növləri'
                },
                component: () => import(/* webpackChunkName: "partner-types" */ '../views/partner-types/partner-types.component.vue')
            },
            {
                path: '/partner-types-add',
                name: 'partner-types-add',
                meta: {
                    name: 'Partnyor növləri'
                },
                component: () => import(/* webpackChunkName: "partner-types-add" */ '../views/partner-types-add/partner-types-add.component.vue')
            },
            {
                path: '/partner-types-edit/:id',
                name: 'partner-types-edit',
                meta: {
                    name: 'Partnyor növləri',
                    edit: true
                },
                component: () => import(/* webpackChunkName: "partner-types-add" */ '../views/partner-types-add/partner-types-add.component.vue')
            },
            {
                path: '/about',
                name: 'about',
                meta: {
                    name: 'Kontent'
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/about/about.component.vue')
            },    {
                path: '/contact',
                name: 'contact',
                meta: {
                    name: 'Əlaqə'
                },
                component: () => import(/* webpackChunkName: "contact" */ '../views/contact/contact.component.vue')
            },  {
                path: '/contact-form',
                name: 'contact-form',
                meta: {
                    name: 'Müraciətlər'
                },
                component: () => import(/* webpackChunkName: "contact-form" */ '../views/contact-form/contact-form.component.vue')
            },
            {
                path: '/contact-form-topic',
                name: 'contact-form-topic',
                meta: {
                    name: 'Müraciət mövzuları'
                },
                component: () => import(/* webpackChunkName: "contact-form-topic" */ '../views/contact-form-topic/contact-form-topic.component.vue')
            },
            {
                path: '/contact-form-topic-add',
                name: 'contact-form-topic-add',
                meta: {
                    name: 'Müraciət mövzuları'
                },
                component: () => import(/* webpackChunkName: "contact-form-topic-add" */ '../views/contact-form-topic-add/contact-form-topic-add.component.vue')
            },
            {
                path: '/contact-form-topic-edit/:id',
                name: 'contact-form-topic-edit',
                meta: {
                    name: 'Müraciət mövzuları',
                    edit: true
                },
                component: () => import(/* webpackChunkName: "contact-form-topic-add" */ '../views/contact-form-topic-add/contact-form-topic-add.component.vue')
            },
            {
                path: '/content',
                name: 'content',
                meta: {
                    name: 'Media kontent'
                },
                component: () => import(/* webpackChunkName: "content" */ '../views/content/content.component.vue')
            },
            {
                path: '/loan-form',
                name: 'loan-form',
                meta: {
                    name: 'Kredit müraciətləri'
                },
                component: () => import(/* webpackChunkName: "loan-form" */ '../views/loan-form/loan-form.component.vue')
            },
            {
                path: '/strategy',
                name: 'strategy',
                meta: {
                    name: 'Strategiyamız'
                },
                component: () => import(/* webpackChunkName: "strategy" */ '../views/strategy/strategy.component.vue')
            },
            {
                path: '/strategy-add',
                name: 'strategy-add',
                meta: {
                    name: 'Strategiyamız'
                },
                component: () => import(/* webpackChunkName: "strategy-add" */ '../views/strategy-add/strategy-add.component.vue')
            },
            {
                path: '/strategy-edit/:id',
                name: 'strategy-edit',
                meta: {
                    name: 'Strategiyamız',
                    edit: true
                },
                component: () => import(/* webpackChunkName: "strategy-add" */ '../views/strategy-add/strategy-add.component.vue')
            },
            {
                path: '/target',
                name: 'target',
                meta: {
                    name: 'Məqsədlərimiz'
                },
                component: () => import(/* webpackChunkName: "target" */ '../views/target/target.component.vue')
            },
            {
                path: '/target-add',
                name: 'target-add',
                meta: {
                    name: 'Məqsədlərimiz'
                },
                component: () => import(/* webpackChunkName: "target-add" */ '../views/target-add/target-add.component.vue')
            },
            {
                path: '/target-edit/:id',
                name: 'target-edit',
                meta: {
                    name: 'Məqsədlərimiz',
                    edit: true
                },
                component: () => import(/* webpackChunkName: "target-add" */ '../views/target-add/target-add.component.vue')
            }, {
                path: '/product',
                name: 'product',
                meta: {
                    name: 'Məhsullar'
                },
                component: () => import(/* webpackChunkName: "product" */ '../views/product/product.component.vue')
            },
            {
                path: '/product-add',
                name: 'product-add',
                meta: {
                    name: 'Məhsullar'
                },
                component: () => import(/* webpackChunkName: "product-add" */ '../views/product-add/product-add.component.vue')
            },
            {
                path: '/product-edit/:id',
                name: 'product-edit',
                meta: {
                    name: 'Məhsullar',
                    edit: true
                },
                component: () => import(/* webpackChunkName: "product-add" */ '../views/product-add/product-add.component.vue')
            },
            {
                path: '/settings',
                name: 'settings',
                meta: {
                    name: 'Tənzimləmələr'
                },
                component: () => import(/* webpackChunkName: "settings" */ '../views/settings/settings.component.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            name: 'Login'
        },
        component: () => import(/* webpackChunkName: "tournaments" */ '../components/layout/login/login.component.vue'),
    },

    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        redirect: {
            name: 'public'
        },
    },
];
