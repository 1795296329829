export default {
    users: 'users',
    login: 'identity/login',
    partners: 'partner',
    partnerTypes: 'partnerType',
    about: 'about',
    content: 'content',
    aboutById: 'aboutByTypeId',
    contentById: 'contentByTypeId',
    aboutTypes: 'abouts',
    contentTypes: 'contentTypes',
    contact: 'contact',
    contactForm: 'contactForm',
    loanForm: 'loanApplication',
    contactFormTopic: 'contactFormTopic',
    strategy: 'strategy',
    target: 'target',
    product: 'product',
    settings: 'identity/changePassword',
}
