import {mapGetters} from "vuex";
import ContentPostModel from "@/views/content/models/content-post.model";
import imageInput from "@/mixins/image-input";

export default {
    name: "content",
    data() {
        return {
            type: null,
            item: {},
            hasData: true
        }
    },
    computed: {
        ...mapGetters({
                contentTypes: 'content/_getItems',
            }
        ),
        isContactPhoto() {
            return this.type==4||this.type==5||this.type==6||this.type==7
        }
    },
    methods: {
        getContent() {
            this.item.image = null;
            this.resfresh = true;

            this.$store.dispatch('content/getItemById', this.type).then((res) => {
                if (!res) {
                    this.hasData = false;
                    this.item = {};
                } else {
                    this.hasData = true;
                    this.item = {...res};
                }
            })
        },
        updateContent(formData) {
            this.$store.dispatch('content/updateItem',{id: this.item.id, data: formData} )
        },
        setContent(formData) {
            this.$store.dispatch('content/setItem', formData)
        },
        approveChanges() {
            let data = new ContentPostModel({...this.item, type: this.type})
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }
            if(this.hasData) {
                this.updateContent(formData);
            }
            else {
                this.setContent(formData);
            }
        }
    },
    created() {
        this.$store.dispatch('content/getItems');
    },
    mixins: [imageInput]

};
