import axios from "axios";
import api from '@/core/api';
import ContentModel from "../models/content.model";
import ContentTypeModel from "../models/content-type.model";

export default {

    getItems({commit}) {
        return axios
            .get(api.contentTypes)
            .then((res) => {
                let data = res.data;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new ContentTypeModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.content, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.content + `/${payload.id}`, payload.data)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.contentById + `/${id}`)
            .then((response) => {
                return new ContentModel(response.data.content);
            })
            .catch(() => {
                return false;
            });
    },
}
