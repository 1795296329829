export default class PartnersPostModel {
    constructor(item) {
        this._setId(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setImage(item);
        this._setLink(item);
        this._setType(item);
    }

    _setId({id}) {
        this.id = id;
    }


    _setTitle({title}) {
        this.name = title;
    }

    _setDescription({description}) {
        this.description = description;
    }


    _setImage({image}) {
        this.file = image;
    }

    _setLink({link}) {
        this.url = link;
    }

    _setType({typeId}) {
        this.partnerTypeId = typeId;
    }

}
