import axios from "axios";
import api from '@/core/api';
import ContactFormTopicModel from "../models/contact-form-topic.model";

export default {

    getItems({commit}) {
        return axios
            .get(api.contactFormTopic)
            .then((res) => {
                let data = res.data.ContactFormTopic.items;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new ContactFormTopicModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.contactFormTopic, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.contactFormTopic + `/${payload.id}`, payload.data)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    deleteItem({commit}, id) {
        return axios
            .delete(api.contactFormTopic + `/${id}`)
            .then(() => {
                commit("deleteItem", id);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.contactFormTopic + `/${id}`)
            .then((response) => {
                return new ContactFormTopicModel(response.data.ContactFormTopic);
            })
            .catch(() => {
                return false;
            });
    },
}
