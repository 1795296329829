export default {
    data() {
        return {
            items: [
                {
                    title: "Kontent",
                    icon: "mdi-folder-multiple",
                    link: "about",
                },
                {
                    title: "Media kontent",
                    icon: "mdi-folder-multiple-image",
                    link: "content",
                },
                {
                    title: "Partnyorlar",
                    icon: "mdi-handshake",
                    link: "partners",
                },
                {
                    title: "Partnyor növü",
                    icon: "mdi-set-left",
                    link: "partner-types",
                },
                {
                    title: "Əlaqə",
                    icon: "mdi-account-box",
                    link: "contact",
                }, {
                    title: "Müraciətlər",
                    icon: "mdi-list-box",
                    link: "contact-form",
                }, {
                    title: "Müraciət mövzuları",
                    icon: "mdi-format-list-checkbox",
                    link: "contact-form-topic",
                },

                {
                    title: "Kredit müraciətləri",
                    icon: "mdi-account-credit-card",
                    link: "loan-form",
                },
                {
                    title: "Strategiyamız",
                    icon: "mdi-strategy",
                    link: "strategy",
                }, {
                    title: "Məqsədlərimiz",
                    icon: "mdi-target",
                    link: "target",
                },{
                    title: "Məhsullar",
                    icon: "mdi-cart",
                    link: "product",
                },
            ],
        };
    },
};
