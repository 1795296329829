import axios from "axios";
import api from '@/core/api';
import PartnersModel from "../models/partners.model";
import PartnersTypeModel from "@/views/partners/models/partners-type.model";

export default {

    getItems({commit}) {
        return axios
            .get(api.partners)
            .then((res) => {
                let data = res.data.partners.items;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new PartnersModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getTypes({commit}) {
        return axios
            .get(api.partnerTypes)
            .then((res) => {
                let data = res.data.partnerType.items;
                commit(
                    "setTypes",
                    data.map((i) => {
                        return new PartnersTypeModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.partners, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.partners + `/${payload.id}`, payload.data)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    deleteItem({commit}, id) {
        return axios
            .delete(api.partners + `/${id}`)
            .then(() => {
                commit("deleteItem", id);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.partners + `/${id}`)
            .then((response) => {
                return new PartnersModel(response.data.partner);
            })
            .catch(() => {
                return false;
            });
    },
}
