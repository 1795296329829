export default class StrategyModel {
    constructor(item) {
        this._setId(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setImage(item);
    }

    _setId({id}) {
        this.id = id;
    }


    _setTitle({title}) {
        this.title = title;
    }

    _setDescription({description}) {
        this.description = description;
    }


    _setImage({fileUrl}) {
        this.image = fileUrl;
    }

}
