import axios from "axios";
import api from '@/core/api';
import ContactModel from "../models/contact.model";

export default {

    getItems({commit}) {
        return axios
            .get(api.contact)
            .then((res) => {
                let data = res.data.contacts;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new ContactModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.contact, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.contact + `/${payload.id}`, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },
}
