export default class ContentModel {
    constructor(item) {
        this._setId(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setTypeId(item);
        this._setHeading(item);
        this._setImage(item);

    }

    _setId({id}) {
        this.id = id;
    }

    _setTitle({title}) {
        this.title = title;
    }

    _setHeading({heading}) {
        this.heading = heading;
    }

    _setDescription({description}) {
        this.description = description;
    }

    _setTypeId({contentType}) {
        this.type = contentType;
    }


    _setImage({fileUrl}) {
        this.image = fileUrl;
    }
}
