export default class ContactFormModel {
    constructor(item) {
        this._setId(item);
        this._setEmail(item);
        this._setPhone(item);
        this._setMessage(item);
        this._setTopic(item);
    }

    _setId({id}) {
        this.id = id;
    }


    _setEmail({email}) {
        this.email = email;
    }

    _setPhone({phone}) {
        this.phone = phone;
    }

    _setMessage({message}) {
        this.message = message;
    }

    _setTopic({contactFormTopic}) {
        this.topic = contactFormTopic.name;
    }
}
