export default class AboutModel {
    constructor(item) {
        this._setId(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setHeading(item);
        this._setTypeId(item);

    }

    _setId({id}) {
        this.id = id;
    }

    _setTitle({title}) {
        this.title = title;
    }

    _setDescription({description}) {
        this.description = description;
    }

    _setHeading({heading}) {
        this.heading = heading;
    }

    _setTypeId({aboutType}) {
        this.type = aboutType;
    }

}
