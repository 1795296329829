export default class ContentPostModel {
    constructor(item) {
        this._setId(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setTypeId(item);
        this._setImage(item);
        this._setHeading(item);

    }

    _setId({id}) {
        this.id = id;
    }

    _setTitle({title}) {
        this.title = title;
    }

    _setHeading({heading}) {
        this.heading = heading;
    }

    _setDescription({description}) {
        this.description = description;
    }

    _setTypeId({type}) {
        this.contentTypeId = type;
    }

    _setImage({image}) {
        this.file = image;
    }

}
