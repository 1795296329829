import axios from "axios";
import store from './../store/index';
import router from '../router';
import Vue from 'vue';

axios.defaults.baseURL = process.env.VUE_APP_API_TEST;
axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem(`admin.${process.env.VUE_APP_NAME}`);

axios.interceptors.request.use(function (config) {
    store.commit("SET_LOADER", true);
    return config;
}, function (error) {
    store.commit("SET_LOADER", true);
    return Promise.reject(error);
});

axios.interceptors.response.use(
    (response) => {
        let method = response.config.method.toUpperCase();
        if (method === 'POST' || method === 'PUT') {
            Vue.$toast.success("Success");
        }
        store.commit("SET_LOADER", false);
        return response;
    },
    (error) => {
        let errMessage = "Xəta baş verdi";
        let noError = true;
        const {
            response: {status},
        } = error;

        if (status === 401) {
            errMessage = "Sessiya müddəti bitmişdir";
            localStorage.removeItem(`admin.${process.env.VUE_APP_NAME}`);
            router.push({name: "login"});
        } else if (status === 404) {
            const excArr = ['about', 'content'];
            const str = error.response.config.url;
            if (!excArr.some(i => str.includes(i))) {
                errMessage = 'Məlumat tapılmadı';
                router.push({name: 'public'}).catch(() => {
                });
            } else {
                noError = false;
            }

        } else {
            let err = error.response.data;
            if (err.length) {
                errMessage = err;
            } else if (err.errors) {
                let keys = Object.keys(err.errors);
                errMessage = err.errors[keys[0]][0];
            }
        }
        if (noError) {
            Vue.$toast.error(errMessage);
        }
        store.commit("SET_LOADER", false);
    }
);

