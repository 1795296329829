export default class PartnerTypesPostModel {
    constructor(item) {
        this._setId(item);
        this._setName(item);
    }

    _setId({id}) {
        this.id = id;
    }


    _setName({name}) {
        this.name = name;
    }
}
