import PartnerTypesPostModel from "@/views/partner-types/models/partner-types-post.model";

export default {
    name: "partner-types-add",
    data() {
        return {
            item: {}
        }
    },
    computed: {
        isEditPage() {
            return this.$route.meta.edit;
        },
    },
    methods: {
        approve() {
            let data = new PartnerTypesPostModel(this.item);
            if (this.isEditPage) {
                this.$store.dispatch("partnerTypes/updateItem", {id: data.id, data: data});
            } else {
                this.$store.dispatch("partnerTypes/setItem", data).then((res) => {
                    if (res) {
                        this.item = {};
                    }
                });
            }
        },
    },
    created() {
        let id = this.$route.params.id;
        this.$store.dispatch("partnerTypes/getItems");
        if (this.isEditPage) {
            this.$store.dispatch("partnerTypes/getItemById", id).then((res) => {
                if (res) {
                    this.item = res;
                }
            });
        }
    },
};
