import login from '../components/layout/login/store';
import partners from '../views/partners/store';
import partnerTypes from '../views/partner-types/store';
import about from '../views/about/store';
import content from '../views/content/store';
import contact from '../views/contact/store';
import strategy from '../views/strategy/store';
import target from '../views/target/store';
import product from '../views/product/store';
import contactForm from '../views/contact-form/store';
import loanForm from '../views/loan-form/store';
import contactFormTopic from '../views/contact-form-topic/store';

export default {
    login,
    partners,
    partnerTypes,
    about,
    content,
    contact,
    contactFormTopic,
    contactForm,
    loanForm,
    strategy,
    target,
    product
}
