import imageInput from "@/mixins/image-input";
import TargetPostModel from "@/views/target/models/target-post.model";

export default {
    name: "target-add",
    data() {
        return {
            item: {}
        }
    },
    computed: {
        isEditPage() {
            return this.$route.meta.edit;
        },
    },
    methods: {
        approve() {
            let data = new TargetPostModel(this.item);
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }
            if (this.isEditPage) {
                this.$store.dispatch("target/updateItem", {id: data.id, data: formData});
            } else {
                this.$store.dispatch("target/setItem", formData).then((res) => {
                    if (res) {
                        this.item = {};
                        this.resfresh = true;
                    }
                });
            }
        },
    },
    created() {
        let id = this.$route.params.id;
        if (this.isEditPage) {
            this.$store.dispatch("target/getItemById", id).then((res) => {
                if (res) {
                    this.item = res;
                }
            });
        }
    },
    mixins: [imageInput]
};
