export default class PartnersModel {
    constructor(item) {
        this._setId(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setImage(item);
        this._setLink(item);
        this._setType(item);
    }

    _setId({id}) {
        this.id = id;
    }


    _setTitle({name}) {
        this.title = name;
    }

    _setDescription({description}) {
        this.description = description;
    }


    _setImage({fileUrl}) {
        this.image = fileUrl;
    }

    _setLink({url}) {
        this.link = url;
    }

    _setType({partnerType}) {
        this.type = partnerType.name;
        this.typeId = partnerType.id;
    }

}
