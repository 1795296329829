export default class LoanFormModel {
    constructor(item) {
        this._setId(item);
        this._setPhone(item);
        this._setPin(item);
        this._setAmount(item);
        this._setInterval(item);
        this._setPercentage(item);
        this._setMonthlyPayment(item);
        this._setAmountType(item);
        this._setIntervalType(item);
    }

    _setId({id}) {
        this.id = id;
    }

    _setPhone({phone}) {
        this.phone = phone;
    }

    _setPin({pin}) {
        this.pin = pin;
    }

    _setInterval({interval}) {
        this.interval = interval;
    }

    _setMonthlyPayment({monthlyPayment}) {
        this.monthlyPayment = monthlyPayment;
    }

    _setAmount({amount}) {
        this.amount = amount;
    }

    _setPercentage({percentageRate}) {
        this.percentage = percentageRate;
    }

    _setAmountType({amountType}) {
        this.amountType = amountType?.name;
    }

    _setIntervalType({intervalType}) {
        this.intervalType = intervalType?.name;
    }

}
