import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            dark: {
                primary: "#3E6F00",
                yellow: "#ffc800",
                secondary: "#ccc",
                accent: "#8c9eff",
                error: "#da1f26",
                red: "#b70000"
            },
            light: {
                primary: "#3E6F00",
                yellow: "#ffc800",
                secondary: "#ccc",
                accent: "#8c9eff",
                error: "#da1f26",
                inputs: "#f9fafb",
                inputs2: "#fefbfb",
                inputs3: "#d6e8d7",
                red: "#da1f26"
            },
        },
    },
});
